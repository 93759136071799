/* istanbul ignore next */
import { renderComponents } from "@btu-tools/master-builder-client";
import "mdn-polyfills/Node.prototype.append";
import { ChatContainer } from "../../../client/containers/ChatContainer";

// eslint-disable-next-line no-underscore-dangle
__webpack_public_path__ = window.__CHAT_MFE_JS__STATIC_URL__;

/*
 * Map of all root components that require hydration/rendering
 * This might be replaced at a later date with a webpack plugin
 */

const componentsToHydrate = { ChatContainer };

document.addEventListener("DOMContentLoaded", () => renderComponents(componentsToHydrate));
