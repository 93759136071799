import {
	ChatState,
	ChatStartResponse,
	ChatQueuesResponse,
	ChatQueues,
	ChatQueueNames,
	ChatStartForm,
	DriverDetails,
	IdvDetails
} from "../../../types";
import { actionTypes } from "./actionTypes";
import { extractQueueIdFromUrl } from "../../../utils/ChatStateUtils";

export const reduceChatQueues = (queuesResponse: ChatQueuesResponse): ChatQueues => {
	return {
		sale: queuesResponse.queueDTOList.find(queue => queue.queueDescKey === "Sale"),
		serv: queuesResponse.queueDTOList.find(queue => queue.queueDescKey === "Serv"),
		post: queuesResponse.queueDTOList.find(queue => queue.queueDescKey === "Post"),
		canc: queuesResponse.queueDTOList.find(queue => queue.queueDescKey === "CANX"),
		openingHours: queuesResponse.openingHours
	};
};
const reduceDriverDetails = (chatStartForm: ChatStartForm): DriverDetails => {
	return {
		name: chatStartForm.name ? chatStartForm.name : "",
		firstName: chatStartForm.firstName ? chatStartForm.firstName : "",
		lastName: chatStartForm.lastName ? chatStartForm.lastName : "",
		email: chatStartForm.email ? chatStartForm.email : "",
		bookingReference: chatStartForm.bookingReference ? chatStartForm.bookingReference : "",
		pickUp: chatStartForm.pickUp ? chatStartForm.pickUp : "",
		dropOff: chatStartForm.dropOff ? chatStartForm.dropOff : ""
	};
};
const reduceIdvDetails = (chatStartResponse: ChatStartResponse): IdvDetails => {
	return {
		time: chatStartResponse.time,
		location: chatStartResponse.location,
		confirmed: chatStartResponse.bookingConfirmed,
		idvPass: chatStartResponse.idvPass,
		bookingPass: chatStartResponse.bookingPass,
		emailPass: chatStartResponse.emailPass,
		ref: chatStartResponse.ref,
		email: chatStartResponse.email,
		name: chatStartResponse.name,
		queueStatusReason: chatStartResponse.queueStatus ? chatStartResponse.queueStatus.queueStatusReason : ""
	};
};
// Responsible for updating state
export const reducer = (state: ChatState, action): ChatState => {
	switch (action.type) {
		case actionTypes.START_CHAT: {
			const { sessionToken } = action.payload as ChatStartResponse;
			return {
				...state,
				name: (action.payload as ChatStartResponse).name,
				chatUrl: (action.payload as ChatStartResponse).url,
				isChatStarted: true,
				isChatEnded: false,
				idvDetails: reduceIdvDetails(action.payload as ChatStartResponse),
				selectedQueueId: extractQueueIdFromUrl((action.payload as ChatStartResponse).url),
				openingType: action.payload.openingType,
				sessionToken
			};
		}
		case actionTypes.SET_QUEUES: {
			return {
				...state,
				chatQueues: reduceChatQueues(action.payload as ChatQueuesResponse)
			};
		}
		case actionTypes.SET_SELECTED_QUEUE: {
			return {
				...state,
				selectedQueueName: action.payload as ChatQueueNames
			};
		}
		case actionTypes.SET_DRIVER_DETAILS: {
			return {
				...state,
				driverDetails: reduceDriverDetails(action.payload as ChatStartForm)
			};
		}
		case actionTypes.CLOSE_AND_RESET_CHAT: {
			return {
				...state,
				...(action.payload as ChatState),
				isChatOpen: false,
				isChatEnded: true
			};
		}
		case actionTypes.SET_CHAT_OPEN_STATUS: {
			return {
				...state,
				isChatOpen: (action.payload ? action.payload : false) as boolean
			};
		}
		case actionTypes.SET_CHAT_ANSWERED_AGENT: {
			return {
				...state,
				isChatAnsweredAgent: (action.payload ? action.payload : false) as boolean
			};
		}
		case actionTypes.SET_CHAT_ANSWERED_CUSTOMER: {
			return {
				...state,
				isChatAnsweredCustomer: (action.payload ? action.payload : false) as boolean
			};
		}
		case actionTypes.SET_CHAT_CONVERSATION_ID: {
			return {
				...state,
				conversationId: (action.payload ? action.payload : "") as string
			};
		}
		case actionTypes.SET_CHAT_ENDED: {
			return {
				...state,
				isChatEnded: (action.payload ? action.payload : false) as boolean
			};
		}
		default: {
			return state;
		}
	}
};
