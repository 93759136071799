export const getSession = (): string => {
	let sessionPayload;
	try {
		sessionPayload = localStorage.getItem("CHAT_SESSION") || "";
	} catch {
		sessionPayload = "";
	}
	return sessionPayload;
};
export const setSession = (token: string): void => {
	if (token && token !== "") {
		try {
			localStorage.setItem("CHAT_SESSION", token);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error("unable to set session", error);
		}
	}
};
export const deleteSession = (): void => {
	try {
		localStorage.removeItem("CHAT_SESSION");
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error("unable to delete session", error);
	}
};
