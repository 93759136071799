import { ChatState } from "../types";

export const hasChatStarted = (chatState: ChatState): boolean => {
	return chatState.isChatStarted && !chatState.isChatEnded;
};
export const hasChatConversation = (chatState: ChatState): boolean => {
	return chatState.isChatAnsweredAgent || chatState.isChatAnsweredCustomer;
};
export const extractQueueIdFromUrl = (url: string): string => {
	if (url) {
		const chatIndex = url.lastIndexOf("/chat/");
		const nameIndex = url.lastIndexOf("?name");
		if (chatIndex > 0 && nameIndex > 0) {
			// eslint-disable-next-line unicorn/prefer-string-slice
			return url.substring(chatIndex + 6, nameIndex);
		}
	}
	return "";
};
