export const actionTypes = {
	START_CHAT: "START_CHAT",
	SET_QUEUES: "SET_QUEUES",
	SET_SELECTED_QUEUE: "SET_SELECTED_QUEUE",
	SET_DRIVER_DETAILS: "SET_DRIVER_DETAILS",
	CLOSE_AND_RESET_CHAT: "CLOSE_AND_RESET_CHAT",
	SET_CHAT_OPEN_STATUS: "SET_CHAT_OPEN_STATUS",
	SET_CHAT_ANSWERED_AGENT: "SET_CHAT_ANSWERED_AGENT",
	SET_CHAT_CONVERSATION_ID: "SET_CHAT_CONVERSATION_ID",
	SET_CHAT_ANSWERED_CUSTOMER: "SET_CHAT_ANSWERED_CUSTOMER",
	SET_CHAT_ENDED: "SET_CHAT_ENDED"
};
