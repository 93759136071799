// eslint-disable-next-line unicorn/filename-case
import React, { createContext, FC, useContext, useReducer } from "react";
import { ChatStartResponse, ChatState, ChatQueueNames, ChatQueuesResponse, ChatStartForm } from "../../../types";
import { reducer } from "./reducer";
import { actionTypes } from "./actionTypes";

export const initialState: ChatState = {
	name: "",
	chatUrl: "",
	isChatOpen: false,
	isChatPreLoaded: false,
	isChatStarted: false,
	isChatEnded: false,
	isChatAnsweredAgent: false,
	isChatAnsweredCustomer: false,
	chatReference: "",
	conversationId: "",
	selectedQueueName: null,
	selectedQueueId: "",
	driverDetails: null,
	idvDetails: null
};

interface ChatStateProviderProps {
	children: React.ReactNode;
	value?: ChatState;
}

const ChatStateContext = createContext(null);

const ChatStateProvider: FC<ChatStateProviderProps> = ({ children, value }) => {
	const [state, dispatch] = useReducer(reducer, value || initialState);
	return <ChatStateContext.Provider value={{ state, dispatch }}>{children}</ChatStateContext.Provider>;
};

const useChatState = (): {
	chatState: ChatState;
	chatActions: {
		startChat: (data: ChatStartResponse, openingType?: string) => void;
		setQueues: (data: ChatQueuesResponse) => void;
		setSelectedQueue: (queueName: ChatQueueNames) => void;
		setDriverDetails: (form: ChatStartForm) => void;
		setChatOpenStatus: (isOpen: boolean) => void;
		setChatAnsweredAgent: (isAnswered: boolean) => void;
		setChatAnsweredCustomer: (isAnswered: boolean) => void;
		setChatConversationId: (conversationId: string) => void;
		setChatEnded: (isEnded: boolean) => void;
		setCloseAndResetChat: () => void;
	};
} => {
	const { state, dispatch } = useContext(ChatStateContext);
	const actions = {
		startChat: (data: ChatStartResponse, openingType?: string) => {
			dispatch({
				type: actionTypes.START_CHAT,
				payload: { ...data, openingType }
			});
		},
		setQueues: (data: ChatQueuesResponse) => {
			dispatch({
				type: actionTypes.SET_QUEUES,
				payload: data
			});
		},
		setSelectedQueue: (queueName: ChatQueueNames) => {
			dispatch({
				type: actionTypes.SET_SELECTED_QUEUE,
				payload: queueName
			});
		},
		setDriverDetails: (form: ChatStartForm) => {
			dispatch({
				type: actionTypes.SET_DRIVER_DETAILS,
				payload: form
			});
		},
		setCloseAndResetChat: () => {
			dispatch({
				type: actionTypes.CLOSE_AND_RESET_CHAT,
				payload: initialState
			});
		},
		setChatOpenStatus: (isOpen: boolean) => {
			dispatch({
				type: actionTypes.SET_CHAT_OPEN_STATUS,
				payload: isOpen
			});
		},
		setChatAnsweredAgent: (isAnswered: boolean) => {
			dispatch({
				type: actionTypes.SET_CHAT_ANSWERED_AGENT,
				payload: isAnswered
			});
		},
		setChatAnsweredCustomer: (isAnswered: boolean) => {
			dispatch({
				type: actionTypes.SET_CHAT_ANSWERED_CUSTOMER,
				payload: isAnswered
			});
		},
		setChatConversationId: (conversationId: string) => {
			dispatch({
				type: actionTypes.SET_CHAT_CONVERSATION_ID,
				payload: conversationId
			});
		},
		setChatEnded: (isEnded: boolean) => {
			dispatch({
				type: actionTypes.SET_CHAT_ENDED,
				payload: isEnded
			});
		}
	};
	return {
		chatState: state,
		chatActions: actions
	};
};

export { ChatStateProvider, useChatState };
